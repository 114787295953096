import { auth, eventsCollection, institutesCollection } from '@/firebase';


/**
 * @description checks more complicated validation. Is called when the user clicks "Hinzufügen"
 * @param startOfSeries, endOfSeries is only needed if it is a series (eg isSeries is true)
 * @param isSeries: true if it is a series, false if it is a single event
 * @returns true if every custom rule is valid, and false if one is not
 * */
 async function checkForm(isSeries, start, end, startOfSeries = '', endOfSeries = ''){
  var formIsValid = false;
  let errors = [];
  let valid = false;
  let error = '';

  /* Rule 1: check if 'end' is less then 3h later then 'start' */
  [valid, error] = eventIsShorterThenThreeHours(start, end);
  if(!valid){
    errors.push(error);
  }

  /* Rule 2: Start time must be before end time */
  [valid, error] = startIsBeforeEnd(start, end);
  if(!valid){
    errors.push(error);
  }

  /* rules for SERIES */

  if(isSeries){
    /* Rule 3: When adding a series, start must be before endDate */
    [valid, error] = startDateBeforeEndDate(startOfSeries, endOfSeries);
    if(!valid){
      errors.push(error);
    }

    /* Rule 4: A series must be longer than seven days */
    [valid, error] = longerThanSevenDays(startOfSeries, endOfSeries);
    if(!valid){
      errors.push(error);
    }
  }


  /* check if all rules were valid */
  if(!errors.length){
    formIsValid = true;
  }

  return [formIsValid, errors];
}


/* RULES FOR ALL EVENTS */

/* Rule 1: check if 'end' is less then 3h later then 'start' 
*/
function eventIsShorterThenThreeHours(start, end) {
  let startDate = new Date('2020-12-24T' + start + ':00Z');
  let endDate = new Date('2020-12-24T' + end + ':00Z');
  let timeDiffInMin = (endDate - startDate) / 60000;
  let error = '';
  let valid = true;

  if(timeDiffInMin > 180) {
    error = 'Das Event darf nicht länger als 3h sein.';
    valid = false;
  }
  return [valid, error]
}


/* Rule 2: Start time must be before end time */
function startIsBeforeEnd(start, end) {
  let valid = true;
  let error = '';
  
  if(start > end){
    valid = false;
    error = "Die Startzeit muss vor der Endzeit liegen.";
  }
  return [valid, error]
}


/* RULES FOR EVENT SERIES */

/* Rule 3: When adding a series, start must be before endDate */
function startDateBeforeEndDate(startOfSeries, endOfSeries){
  let valid = true;
  let error = '';

  if (startOfSeries > endOfSeries) {
    valid = false;
    error = 'Das Startdatum muss vor dem Enddatum liegen.';
  }
  return [valid, error]
}

/* Rule 4: When adding a series with less than 7 days, check if there is a date with weekday in it 
@param weekday is an object with name and index
*/
function longerThanSevenDays(startOfSeries, endOfSeries) {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  let start = new Date(startOfSeries);
  let end = new Date(endOfSeries);
  let daysBetween = Math.round(Math.abs((start - end) / oneDay));
  let valid = true;
  let error = '';

  if(daysBetween < 7) {
    valid = false;
    error = "Eine Serie muss mindestens sieben Tage lang sein."
  }
  return [valid, error];
}


/* CHECKING FOR CONFLICTING EVENTS */

/**
 * @description checks if there are already existing events that would conflict with given event. Eg. there is already somebody in that room at this time
 * @param event Object with properties date, start, end, room.
 * @returns true if there are no conflicting events and false if there are conflicting events
 * idsToIgnore is initialized with [], if no parameter is given
 */
 async function noConflicts(event, idsToIgnore = []) {
  let errors = [];

  let noOtherEventsAreConflictingBool = true;
  let conflictingEvents = [];

  /* only check if there are other conflicting events at that time and room, if it is not online */
  if(event.room.name != 'Online'){
    [noOtherEventsAreConflictingBool, conflictingEvents] = await noOtherEventsAreConflicting(event, idsToIgnore);
  } else {
    noOtherEventsAreConflictingBool = true;
  }

  let teacherHasTimeBool = false;
  let studentHasTimeBool = false;
  let error = '';
  [teacherHasTimeBool, error] = await teacherHasTime(event, idsToIgnore);
  if(!teacherHasTimeBool){
    errors.push(error);
  }

  [studentHasTimeBool, error] = await studentHasTime(event, idsToIgnore);
  if(!studentHasTimeBool){
    errors.push(error);
  }
  
  return [noOtherEventsAreConflictingBool && teacherHasTimeBool && studentHasTimeBool, 
    conflictingEvents, 
    errors];
}

/**
 * @description checks if id is in the array of ids to ignore
 */
function ignoreEventWithThisID(id, idsToIgnore){
  return idsToIgnore.includes(id);
}

/**
 * @description checks if there are already existing events that would conflict with given event. Eg. there is already somebody in that room at this time
 * @param event Object with properties date, start, end, room.
 * @returns true if there are no conflicting events and false if there are conflicting events
 */
async function noOtherEventsAreConflicting(event, idsToIgnore) {
  let noConflictingEvents = true; //return Value
  let possiblyConflictingEvents = [];
  let conflictingEvents = [];

  //Get the institueId of the currently logged in user
  let instituteId = (await auth.currentUser.getIdTokenResult()).claims.instituteId;

  /* Geting events at this date and room from firebase whos start time is less than the end time of the new event. Those could possibly conflict. */
  await eventsCollection
    .where("instituteId", "==", instituteId)
    .where("date", "==", event.date)
    .where("roomId", "==", event.room.id)
    //.where("end", ">", this.start) //get leider nicht eine Query nach zwei Feldern zu filtern. Darum filtern wir diese unten in der Schleife raus
    .where("start", "<", event.end) 
    .orderBy('start')
    .get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        let currentEvent = doc.data();
        currentEvent.id = doc.id;
        possiblyConflictingEvents.push(currentEvent);
      })
    })
  /* all of those events whos end time is greater then the start time of the new event are realy a conflict. They are added to this.conflictingEvents */
  for (let i=0; i < possiblyConflictingEvents.length; i++){

    let ignoreEvent = ignoreEventWithThisID(possiblyConflictingEvents[i].id, idsToIgnore);
   
    if ((possiblyConflictingEvents[i].end > event.start) && !ignoreEvent){
      /* get the teacher of the conflicting event */
      var teacherName = await getPersonsName(possiblyConflictingEvents[i].teacherRef);
      possiblyConflictingEvents[i].teacher = teacherName;
      /* add event to conflicting Events array */
      conflictingEvents.push(possiblyConflictingEvents[i]);
      noConflictingEvents = false; //since there are conflicting events, set this to false
    }
  }
  return [noConflictingEvents, conflictingEvents];
}

/**
 * @description returns a sting with first name and last name of a user given the reference of a teacher doc
 * @param teacherRef: firestore Reference to a document of a teacher
 */
async function getPersonsName(ref) {
  let personsName = '';
  const personDoc = await ref.get();
  if (personDoc.exists) {
    personsName = personDoc.data().firstname + " " + personDoc.data().lastname;
  } else { console.log('No such document') }
  return personsName
}

/**
 * @description checks if the teacher of this event has an other event during this time
 */
async function teacherHasTime(event, idsToIgnore) {
  let teacherHasTimeBool = true; //return Value
  let teacherConflictEvent = {};
  let error = '';

  //Get the institueId of the currently logged in user
  let instituteId = (await auth.currentUser.getIdTokenResult()).claims.instituteId;

  /* Geting events at this date and teacher from firebase whos start time is less than the end time of the new event. Those could possibly conflict. */
  await eventsCollection
    .where("instituteId", "==", instituteId)
    .where("date", "==", event.date)
    .where("teacherRef", "==", event.teacher.ref)
    .where("start", "<", event.end)
    .get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        let data = doc.data();
        let ignoreEvent = ignoreEventWithThisID(doc.id, idsToIgnore);
        if(data.end > event.start && !ignoreEvent){
          teacherConflictEvent = data;
          teacherHasTimeBool = false;
        }
      })
    })

    if(!teacherHasTimeBool){
      /* get name of the teacher */
      let personsName = await getPersonsName(teacherConflictEvent.studentRef);
      error = 'Lehrer:in ' + event.teacher.name + ' hat von ' + teacherConflictEvent.start + ' - ' + teacherConflictEvent.end 
        + ' schon eine Stunde mit ' + personsName + '.';
    }

  return [teacherHasTimeBool, error]
}

/**
 * @description checks if the student of this event has an other event during this time
 */
 async function studentHasTime(event, idsToIgnore) {
  let studentHasTimeBool = true; //return Value
  let studentConflictEvent = {};
  let error = '';

  //Get the institueId of the currently logged in user
  let instituteId = (await auth.currentUser.getIdTokenResult()).claims.instituteId;

  /* Geting events at this date and teacher from firebase whos start time is less than the end time of the new event. Those could possibly conflict. */
  await eventsCollection
    .where("instituteId", "==", instituteId)
    .where("date", "==", event.date)
    .where("studentRef", "==", event.student.ref)
    .where("start", "<", event.end) 
    .get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        let data = doc.data();
        let ignoreEvent = ignoreEventWithThisID(doc.id, idsToIgnore);
        if(data.end > event.start && !ignoreEvent){
          studentConflictEvent = data;
          studentHasTimeBool = false;
        }
      })
    })

    if(!studentHasTimeBool){
      /* get name of the teacher */
      let personsName = await getPersonsName(studentConflictEvent.teacherRef);
        error = 'Schüler:in ' + event.student.name + ' hat von ' + studentConflictEvent.start + ' - ' + studentConflictEvent.end 
          + ' schon eine Stunde mit ' + personsName + '.';  
    }

  return [studentHasTimeBool, error]
}

/**
 * @description 
 * */
async function calculateDatesForSeries(startOfSeries, endOfSeries, weekday){
  let regularDates = []; //empty array
  let schoolfreeDays = await getSchoolfreeDays();

  /* convert dates into js type Date() */
  let startDate = new Date(startOfSeries + "T12:00:00Z"); //Time 12:00 is needed to avoid bug in switching days if change to summertime happens
  let endDate = new Date(endOfSeries + "T12:00:00Z");

  /* calculate the first date after startDate which is on the required weekday (on this.weekday) */
  let startWeekdayIndex = startDate.getDay(); //0 means Sunday, 1 monday,... , 6 saturday
  let daysToBeAdded = weekday.index - startWeekdayIndex;
  if (daysToBeAdded < 0){ //if it is negative, add seven days to get date after start date at the reqired weekday
    daysToBeAdded += 7
  }

  let nextDate = startDate; //set Date to the start Date
  nextDate.setDate(nextDate.getDate() + daysToBeAdded); //add number of days to get to the date of the weekday wanted

 /*  add it to regularDates array, if it is not a schoolfree day */
  let nextDateIso = nextDate.toISOString().substring(0, 10);
  if(!schoolfreeDays.includes(nextDateIso)) {
    regularDates.push(nextDateIso); //push date into array
  }
  

  /* if we are still below endDate, add another 7 days */
  while(nextDate <= endDate){
    nextDate = new Date(nextDate.setDate(nextDate.getDate() + 7)); //add seven days
    nextDateIso = nextDate.toISOString().substring(0, 10);

    /*  add it to regularDates array, if it is not a schoolfree day */
    if(!schoolfreeDays.includes(nextDateIso)) {
      if(nextDate.getDay() == weekday.index){
        regularDates.push(nextDate.toISOString().substring(0, 10));  //push date into array
      } else {
        throw "The calculated day has not the excepted weekday: " + nextDate.getDay() + " vs " + weekday.index;
      }
    }
  }
  regularDates.pop(); //remove the last date, since it is past endDate
  return regularDates
}

/**
  * @description gets the instiute settings (min Duration of an Event, rooms etc) from firestore and setts this.rooms and this.room
  */
async function getSchoolfreeDays(){
  let schoolfreeDays = [];

  //Get the institueId of the currently logged in user
  let instituteId = (await auth.currentUser.getIdTokenResult()).claims.instituteId;

  //get institute settings
  const doc = await institutesCollection.doc(instituteId).get();
  if (doc.exists) {
    schoolfreeDays = doc.data().schoolfreeDays;
  } else {
    throw 'the institutedocument could not be loaded from the db';
  }

  return schoolfreeDays;
}
  
  
  export {
    checkForm,
    noConflicts,
    calculateDatesForSeries,
  };